import type Doc from '../../classes/Doc';

export function docFromSnapshot<T>(snapshot: firebase.default.firestore.DocumentSnapshot): Doc<T> {
  const id = snapshot.id;
  // const docPath = snapshot.ref.path;
  const data = snapshot.data() as T;
  // return { id, docPath, ...data };
  return { id, ...data };
}

export function docsFromSnapshot<T>(snapshot: firebase.default.firestore.QuerySnapshot): Doc<T>[] {
  return snapshot.docs.map(snap => docFromSnapshot<T>(snap));
}
