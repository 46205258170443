import styled from 'styled-components/macro';

const Label = styled.label`
  display: block;
  cursor: pointer;
  line-height: 1.5;
  font-weight: 500;
  color: ${({ theme }) => theme.gray[700]};
  margin-bottom: 16px;
`;
export default Label;
