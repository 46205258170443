import { DefaultTheme } from 'styled-components/macro';

const defaultTheme: DefaultTheme = {
  // primary: {
  //   50: '#f0fdfa',
  //   100: '#ccfbf1',
  //   200: '#99f6e4',
  //   300: '#5eead4',
  //   400: '#2dd4bf',
  //   500: '#14b8a6',
  //   600: '#0d9488',
  //   700: '#0f766e',
  //   800: '#115e59',
  //   900: '#134e4a',
  // },
  gray: {
    50: '#FAFAFA',
    100: '#F4F4F5',
    200: '#E4E4E7',
    300: '#D4D4D8',
    400: '#A1A1AA',
    500: '#71717A',
    600: '#52525B',
    700: '#3F3F46',
    800: '#27272A',
    900: '#18181B',
  },
  danger: {
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d',
  },
  ease: {
    standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    decelerate: 'cubic-bezier(0, 0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0, 1, 1)',
  },
};

export default defaultTheme;
