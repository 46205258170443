import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 24px 12px;
  border-top: 1px solid ${({ theme }) => theme.gray[200]};

  font-size: 20px;
  font-weight: 500;
`;

interface Props {}
const PanelFooter: React.FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
export default PanelFooter;
