import styled from 'styled-components/macro';
import { Redirect, Route, Switch } from 'react-router-dom';
import Nav from './components/Nav';
// State
import { useRecoilValue } from 'recoil';
import currentUserState from './state/currentUser';
// Login
import firebase, { auth } from './lib/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { ReactComponent as RevelSVG } from './svg/Revel.svg';
import Spacer from './components/Spacer';
// Pages
import DashboardPage from './pages/DashboardPage';
import FeedPage from './pages/FeedPage';
import EventsPage from './pages/EventsPage';
import ProfilesPage from './pages/ProfilesPage';
import { useEffect } from 'react';

// Layout styles
const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const PageWrapper = styled.div`
  flex: 1;
  padding: 0 24px;
  overflow: hidden;

  display: flex;
  align-items: center;
`;
const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

// App component
export default function App() {
  const user = useRecoilValue(currentUserState);
  useEffect(() => {
    if (user) {
      console.log('user', user);
      (async () => {
        const { claims } = await user.getIdTokenResult();
        if (!claims.admin) {
          auth.signOut();
        }
      })();
    }
  }, [user]);

  if (!user) {
    // Configure FirebaseUI.
    const uiConfig = {
      // Popup signin flow rather than redirect flow.
      // signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      // signInSuccessUrl: '/signedIn',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID
      ],
    };
    return (
      <LoginWrapper className='fade-in'>
        <RevelSVG width='128px' />
        <Spacer height='24px' />
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        <Spacer height='48px' />
      </LoginWrapper>
    );
    // return <div>Login</div>;
  }

  return (
    <AppWrapper>
      <Nav />

      <PageWrapper>
        <Switch>
          <Route path='/dashboard'>
            <DashboardPage />
          </Route>

          <Route path='/feed'>
            <FeedPage />
          </Route>

          <Route path='/events/:id?'>
            <EventsPage />
          </Route>

          <Route path='/profiles'>
            <ProfilesPage />
          </Route>

          <Redirect to='/events' />
        </Switch>
      </PageWrapper>
    </AppWrapper>
  );
}
