import { NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import Event from '../../classes/Event';
import Panel from '../../components/Panel';
import PanelHeader from '../../components/PanelHeader';
import PanelPlaceholder from '../../components/PanelPlaceholder';
import PrimaryButton from '../../components/PrimaryButton';
import Scrollable from '../../components/Scrollable';
import Spacer from '../../components/Spacer';
import EventForm from './EventForm';
import useCollection from '../../hooks/useCollection';
import { db } from '../../lib/firebase';
import EventLink from './EventLink';

// const EventLink = styled(NavLink)`
//   display: block;

//   padding: 12px;
//   border-right: 4px solid white;

//   text-decoration: none;
//   color: ${({ theme }) => theme.gray[900]};
//   &:hover {
//     border-right: 4px solid ${({ theme }) => theme.gray[300]};
//     background-color: ${({ theme }) => theme.gray[50]};
//   }
//   &.active {
//     background-color: ${({ theme }) => theme.gray[100]};
//     border-right: 4px solid ${({ theme }) => theme.gray[700]};
//   }
// `;

interface Props {}
const EventsPage: React.FC<Props> = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [events, eventsLoading] = useCollection<Event>(db.collection('events'));
  const selectedEvent = events?.find(event => event.id === id);
  return (
    <>
      <Panel>
        <PanelHeader>
          Events
          {!id && (
            <PrimaryButton size='sm' onClick={() => history.push('/events/new')}>
              Add Event
            </PrimaryButton>
          )}
        </PanelHeader>

        <Scrollable>
          {events?.map(event => (
            <EventLink key={event.id} event={event} />
          ))}
        </Scrollable>
      </Panel>
      <Spacer width='24px' />

      <Switch>
        <Route path='/events/new'>
          <EventForm />
        </Route>

        <Route path='/events/:id'>
          <EventForm editEventId={id} key={id} />
          {/* <EventForm editEventId={id} editEventDoc={selectedEvent} key={id} /> */}
          {/* {!selectedEvent && (
            <>
              <PanelPlaceholder />
              <Spacer width='24px' />
              <PanelPlaceholder />
            </>
          )} */}
        </Route>

        {/* Placeholder */}
        <Route exact path='/events'>
          <PanelPlaceholder />
          <Spacer width='24px' />
          <PanelPlaceholder />
        </Route>
      </Switch>
    </>
  );
};
export default EventsPage;
