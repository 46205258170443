import { atom, selector } from 'recoil';
import firebase from '../lib/firebase';

const currentUserState = atom<firebase.User | null>({
  key: 'currentUserState',
  default: null,
  dangerouslyAllowMutability: true,
});
export default currentUserState;

export const uidSelector = selector({
  key: 'uidSelector',
  get: ({ get }) => {
    const currentUser = get(currentUserState);
    return currentUser?.uid ?? null;
  },
});
