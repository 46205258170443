import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
// Classes
import Event from '../../classes/Event';
// Libs / helpers
import { db, storage } from '../../lib/firebase';
import isEqual from 'lodash/isEqual';
import axios from 'axios';
import { getPhotoUrl } from '../../lib/helpers/photo';
// Hooks
import { useDropzone } from 'react-dropzone';
import useDoc from '../../hooks/useDoc';
// Components
import Input from '../../components/formElements/Input';
import Label from '../../components/formElements/Label';
import Panel from '../../components/Panel';
import PanelHeader from '../../components/PanelHeader';
import PrimaryButton from '../../components/PrimaryButton';
import Scrollable from '../../components/Scrollable';
import SecondaryButton from '../../components/SecondaryButton';
import Spacer from '../../components/Spacer';
import PanelFooter from '../../components/PanelFooter';
import Loader from '../../components/Loader';
import PanelPlaceholder from '../../components/PanelPlaceholder';

// Config
const ACCEPTED_FILETYPES = ['image/jpeg', 'image/png'];
const MAX_FILESIZE = 8000000; // 8mb

// Styles
const PreviewImgRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 16px;
`;
const PreviewImgSquare = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: 160px;
  height: 160px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-color: ${({ theme }) => theme.gray[200]};
`;
const PreviewImgWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-top: 75%;
  padding-bottom: 75%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-color: ${({ theme }) => theme.gray[200]};

  text-align: center;
  color: ${({ theme }) => theme.gray[400]};
  font-weight: 500;
`;
const EventNotFound = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 64px;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.gray[500]};
`;
const Error = styled.div`
  margin-top: 8px;
  font-weight: 500;
  color: ${({ theme }) => theme.danger[500]};
`;
const Dropzone = styled.div`
  padding: 16px;
  background-color: white;
  /* background-color: ${({ theme }) => theme.gray[50]}; */
  border-radius: 8px;
  border: 2px dashed ${({ theme }) => theme.gray[100]};
  text-align: center;
`;
const PreviewName = styled.div`
  padding: 12px;
  font-size: 22px;
  font-weight: 500;
`;

// Component
interface Props {
  editEventId?: string;
}
const EventForm: React.FC<Props> = ({ editEventId }) => {
  const history = useHistory();

  const isEditing = !!editEventId;
  // const id = useMemo(() => editEventId || db.collection('events').doc().id, [editEventId]);

  const [eventDoc, eventDocIsLoading] = useDoc<Event>(editEventId ? `events/${editEventId}` : null);
  const eventId = useMemo(() => editEventId || db.collection('events').doc().id, [editEventId]);
  const { id, ...event } = useMemo(() => eventDoc || { id: eventId, ...new Event() }, [
    eventDoc,
    eventId,
  ]);
  const [values, setValues] = useState(event);

  // If event changes in database, update form values accordingly
  useEffect(() => {
    if (eventDoc) {
      const { id, ...event } = eventDoc;
      setValues(event);
    }
  }, [eventDoc]);

  // Fetch doc if has event ID but no doc preloaded from
  // const [eventNotFound, setEventNotFound] = useState(false);
  // useEffect(() => {
  //   (async () => {
  //     if (editEventId && !editEventDoc && !values.name) {
  //       const eventSnap = await db.collection('events').doc(editEventId).get();
  //       if (!eventSnap.exists) return setEventNotFound(true);

  //       const eventDoc = docFromSnapshot<Event>(eventSnap);
  //       const { id, ...event } = eventDoc;
  //       setValues(event);
  //     }
  //   })();
  // }, [editEventDoc, editEventId, values.name]);

  // Photo stuff
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    multiple: false,
    accept: ACCEPTED_FILETYPES.join(', '),
    maxSize: MAX_FILESIZE,
    noClick: true, // Don't click anywhere in the box to open file dialog
    noKeyboard: true, // Don't allow :focus on entire box
    onDrop: () => {
      setPhoto(prev => ({ ...prev, error: '' })); // Reset error
    },
    onDropAccepted: files => {
      console.log('files', files);
      setPhoto(prev => ({ ...prev, file: files[0], previewUrl: URL.createObjectURL(files[0]) }));
      // const file: PhotoFile = Object.assign(files[0], {
      //   preview: URL.createObjectURL(files[0]),
      // });
      // setPhotoFile(file);
    },
    onDropRejected: rejections => {
      if (rejections.length > 1) {
        setPhoto(prev => ({ ...prev, error: 'Only 1 file allowed at a time' }));
      } else if (!ACCEPTED_FILETYPES.includes(rejections[0].file.type)) {
        setPhoto(prev => ({ ...prev, error: 'Only .jpg and .png file types supported' }));
      } else if (rejections[0].file.size >= MAX_FILESIZE) {
        setPhoto(prev => ({ ...prev, error: 'Too big! Files should be less than 8mb' }));
      } else {
        setPhoto(prev => ({
          ...prev,
          error: 'An error occurred. Please try again or contact support.',
        }));
        console.error(rejections);
      }
    },
  });
  const [photo, setPhoto] = useState({
    // isLoading: isEditing,
    file: null as File | null,
    previewUrl: isEditing ? getPhotoUrl(id, 1024) : '',
    error: '',
  });
  // Fetch photo from storage if editing

  // const [photoUrl, setPhotoUrl] = useState();
  const fetchPhotoFromUrl = useCallback(async (path: string) => {
    setPhoto(prev => ({ ...prev, isLoading: true, error: '' }));
    if (path.trim()) {
      try {
        const url = new URL(path); // Ensuring input is valid URL
        const blob = (await axios.get('https://anycors.com/?q=' + path, { responseType: 'blob' }))
          .data;
        console.log('url', url);
        const photoExt = url.pathname.split('.').pop();
        const file = new File([blob], `photo.${photoExt}`, { type: blob.type });
        const previewUrl = URL.createObjectURL(file);
        console.log('file', file);
        setPhoto(prev => ({ ...prev, isLoading: false, file, previewUrl }));
      } catch {
        setPhoto(prev => ({ ...prev, isLoading: false, error: 'Could not fetch image' }));
      }
    }
  }, []);
  // This revokes the data uri on unmount ( if present ) to "avoid memory leaks"
  useEffect(
    () => () => {
      photo?.previewUrl && URL.revokeObjectURL(photo.previewUrl);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Validation stuff
  const [isSubmitting, setIsSubmitting] = useState(false);
  const eventHasChanged = photo.file || !isEqual(event, values);
  const disableSubmit =
    isSubmitting ||
    (isEditing && eventDocIsLoading) ||
    !photo.previewUrl ||
    !values.name.trim() ||
    !values.streamUrl.trim() ||
    !eventHasChanged;

  // Delete
  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleDelete = useCallback(() => {
    if (!confirmDelete) return setConfirmDelete(true);

    const eventRef = db.collection('events').doc(id);
    eventRef.delete();
    history.push(`/events`);
  }, [confirmDelete, history, id]);

  // Submit
  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);

    let draft = false;
    const eventRef = db.collection('events').doc(id);
    if (photo?.file) {
      const photoExt = photo.file.name.split('.').pop();
      console.log('photoExt', photoExt);
      storage.ref(`${eventRef.path}/${id}.${photoExt}`).put(photo.file);
      draft = true;
      setPhoto(prev => ({ ...prev, file: null, previewUrl: '' }));
      photo?.previewUrl && URL.revokeObjectURL(photo.previewUrl);
    }
    eventRef.set({ ...values, draft });
    !isEditing && history.push(`/events/${id}`);
    setIsSubmitting(false);
  }, [history, id, isEditing, photo.file, photo.previewUrl, values]);

  // No Event Found
  const eventNotFound = isEditing && !eventDocIsLoading && !eventDoc;
  if (eventNotFound) {
    console.log('event not found');
    return (
      <>
        <Panel>
          <PanelHeader>Event Details</PanelHeader>
          <EventNotFound>
            Event Not Found!
            <SecondaryButton size='sm' onClick={() => history.push('/events')}>
              Close
            </SecondaryButton>
          </EventNotFound>
        </Panel>
        <Spacer width='24px' />
        <PanelPlaceholder />
      </>
    );
  }

  return (
    <>
      {/* Left panel ( Event Form ) */}
      <Panel>
        {/* Header */}
        <PanelHeader>
          Event Details
          <small>
            {!isEditing && 'New Event'}
            {isEditing && eventHasChanged && 'Draft'}
          </small>
        </PanelHeader>
        <Loader show={isSubmitting || (isEditing && eventDocIsLoading)} />

        {/* Form */}
        <Scrollable padding='16px 24px' endBuffer='32px'>
          {/* Photo drop input */}

          {/* Photo input */}
          {!photo.previewUrl && (
            <Label htmlFor='event-photo'>
              Photo
              <Dropzone {...getRootProps()}>
                {/* Drag (browse) input */}
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>Drop it like it's hawt! 🔥🔥🔥</div>
                ) : (
                  <div>
                    Drag and drop an image or{' '}
                    <PrimaryButton size='sm' onClick={open}>
                      Browse
                    </PrimaryButton>
                  </div>
                )}
                <Spacer height='16px' />
                OR enter image url:
                <Spacer height='12px' />
                {/* Type (external URL) input */}
                <Input
                  id='event-photo'
                  name='event-photo'
                  type='text'
                  placeholder='http://'
                  onFocus={e => e.target.select()}
                  disabled={isSubmitting}
                  onChange={({ target }) => fetchPhotoFromUrl(target.value)}
                />
                {!!photo.error && <Error>{photo.error}</Error>}
              </Dropzone>
            </Label>
          )}
          {/* Photo preview */}
          {!!photo.previewUrl && (
            <PreviewImgRow>
              {/* <PreviewImgSquare style={{ backgroundImage: `url(${photo.preview})` }} /> */}
              <PreviewImgSquare
                style={
                  photo.previewUrl && !values.draft
                    ? { backgroundImage: `url(${photo.previewUrl})` }
                    : {}
                }
              />
              <Spacer width='12px' />
              <SecondaryButton
                size='sm'
                onClick={() => {
                  photo?.previewUrl && URL.revokeObjectURL(photo.previewUrl);
                  setPhoto(prev => ({ ...prev, file: null, previewUrl: '' }));
                }}
              >
                Remove
              </SecondaryButton>
            </PreviewImgRow>
          )}

          <Label htmlFor='event-name'>
            Name
            <Input
              id='event-title'
              name='event-title'
              type='text'
              placeholder='Title'
              disabled={isSubmitting}
              value={values.name}
              onChange={({ target }) => setValues(prev => ({ ...prev, name: target.value }))}
            />
          </Label>

          <Label htmlFor='event-stream-url'>
            Stream URL
            <Input
              id='event-stream-url'
              name='event-stream-url'
              type='text'
              placeholder='https://'
              disabled={isSubmitting}
              value={values.streamUrl}
              onChange={({ target }) => setValues(prev => ({ ...prev, streamUrl: target.value }))}
            />
          </Label>

          {/* Delete event */}
          {isEditing && (
            <>
              <Spacer height='24px' />
              {!confirmDelete && (
                <SecondaryButton size='sm' onClick={handleDelete}>
                  Delete event
                </SecondaryButton>
              )}
              {confirmDelete && (
                <PrimaryButton size='sm' onClick={handleDelete}>
                  You sure?
                </PrimaryButton>
              )}
              {confirmDelete && (
                <SecondaryButton size='sm' onClick={() => setConfirmDelete(false)}>
                  Cancel delete
                </SecondaryButton>
              )}
            </>
          )}
        </Scrollable>
        {/* Footer controls ( Save + Cancel ) */}
        <PanelFooter>
          <SecondaryButton
            size='sm'
            disabled={isSubmitting}
            onClick={() => history.push('/events')}
          >
            {eventHasChanged ? 'Cancel' : 'Close'}
          </SecondaryButton>
          <PrimaryButton disabled={disableSubmit || isSubmitting} onClick={handleSubmit}>
            {isEditing ? 'Publish changes' : 'Publish Event'}
          </PrimaryButton>
        </PanelFooter>
      </Panel>

      {/* Right panel ( Preview Event ) */}
      <Spacer width='24px' />
      <Panel>
        <PanelHeader>Event Preview</PanelHeader>
        <Scrollable endBuffer='64px'>
          <PreviewImgWrapper
            style={
              photo.previewUrl && !values.draft
                ? { backgroundImage: `url(${photo.previewUrl})` }
                : {}
            }
          >
            {!photo.previewUrl && 'NO PHOTO'}
          </PreviewImgWrapper>
          <PreviewName>{values.name}</PreviewName>
        </Scrollable>
      </Panel>
    </>
  );
};
export default EventForm;
