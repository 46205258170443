import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import Doc from '../../classes/Doc';
import Event from '../../classes/Event';
import { useEffect, useState } from 'react';
import { storage } from '../../lib/firebase';
import Spacer from '../../components/Spacer';
import { getPhotoUrl } from '../../lib/helpers/photo';

const Wrapper = styled(NavLink)`
  display: flex;
  align-items: center;

  padding: 12px;
  border-right: 4px solid white;

  text-decoration: none;
  color: ${({ theme }) => theme.gray[900]};
  &:hover {
    border-right: 4px solid ${({ theme }) => theme.gray[300]};
    background-color: ${({ theme }) => theme.gray[50]};
  }
  &.active {
    background-color: ${({ theme }) => theme.gray[100]};
    border-right: 4px solid ${({ theme }) => theme.gray[700]};
  }
`;
const ImgWrapper = styled.div`
  border-radius: 6px;
  width: 48px;
  height: 48px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-color: ${({ theme }) => theme.gray[200]};
  border: 1px solid ${({ theme }) => theme.gray[300]};
`;

interface Props {
  event: Doc<Event>;
}
const EventLink = ({ event }: Props) => {
  // const [imgUrl, setImgUrl] = useState<string | null>(null);
  // useEffect(() => {
  //   const fetchImg = async (idx = 0) => {
  //     if (idx < 10) {
  //       try {
  //         const url = await storage
  //           .ref(`events/${event.id}/${event.id}_thumb@12.jpg`)
  //           .getDownloadURL();
  //         setImgUrl(url);
  //       } catch {
  //         setTimeout(() => fetchImg(idx + 1), 500);
  //       }
  //     }
  //   };
  //   fetchImg();
  // }, [event.id]);

  return (
    <Wrapper to={`/events/${event.id}`}>
      {!event.draft && (
        <ImgWrapper style={{ backgroundImage: `url(${getPhotoUrl(event.id, 128)})` }} />
      )}
      {/* <ImgWrapper style={imgUrl ? { backgroundImage: `url(${imgUrl})` } : {}} /> */}
      <Spacer width='12px' />
      {event.name}
    </Wrapper>
  );
};
export default EventLink;
