import { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import Doc from '../classes/Doc';
import { docFromSnapshot } from '../lib/helpers/doc';

export default function useDoc<T>(
  docPath: string | null | false | undefined,
  options: { ignoreCache?: boolean } = {}
) {
  const [stream, setStream] = useState<[Doc<T> | null, boolean]>([null, true]);

  useEffect(() => {
    try {
      if (docPath && docPath.trim()) {
        setStream(([prevDoc]) => [prevDoc, true]);
        return db.doc(docPath).onSnapshot(
          {
            includeMetadataChanges: !!options.ignoreCache,
          },
          snapshot => {
            // Todo: I'm not currently using this. Could possibly remove
            // Don't honor data from cache if setting says to ignore
            if (options.ignoreCache && snapshot.metadata.fromCache) return;
            snapshot.exists
              ? setStream([docFromSnapshot<T>(snapshot), false])
              : setStream([null, false]);
          },
          error => {
            console.error(`Error streaming firestore doc '${docPath}'. ${error.message}`);
          }
        );
      }
    } catch (error) {
      console.error(`Error streaming firestore doc '${docPath}'. ${error.message}`);
    }
  }, [docPath, options.ignoreCache]);

  return stream;
}
