import Panel from '../components/Panel';
import PanelHeader from '../components/PanelHeader';
import PanelPlaceholder from '../components/PanelPlaceholder';
import Spacer from '../components/Spacer';

interface Props {}
const ProfilesPage: React.FC<Props> = () => {
  return (
    <>
      <Panel>
        <PanelHeader>Profiles</PanelHeader>
      </Panel>
      <Spacer width='24px' />

      <PanelPlaceholder />
      <Spacer width='24px' />

      <PanelPlaceholder />
    </>
  );
};
export default ProfilesPage;
