import styled from 'styled-components/macro';

const Wrapper = styled.div`
  position: relative;
  flex: 1;

  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;

  box-shadow: 2px 4px 12px 2px rgba(26, 26, 26, 0.12);
  border-radius: 6px 6px 0px 0px;
`;

interface Props {}
const Panel: React.FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
export default Panel;
