import styled from 'styled-components/macro';

const Wrapper = styled.div`
  flex: 1;
  flex-shrink: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.gray[100]};

  box-shadow: 0 0 0 1px ${({ theme }) => theme.gray[200]};
  border-radius: 6px 6px 0px 0px;
`;

interface Props {}
const PanelPlaceholder: React.FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
export default PanelPlaceholder;
