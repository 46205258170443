import styled, { keyframes } from 'styled-components/macro';
import CSSTransition from 'react-transition-group/CSSTransition';

// Animations
const LeadAnimation = keyframes`
  0%{
    opacity: 0;
    transform: scale(0.05, 0.5);
  }
  66%, 100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const CoverAnimation = keyframes`
  0%, 33%{
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`;

// Styles
const Wrapper = styled.div`
  z-index: 2;
  position: relative;
  width: 100%;
  height: 4px;
  margin-bottom: -4px;
  overflow: hidden;
  background-color: white;

  &.enter,
  &.appear {
    opacity: 0;
  }
  &.appear-active,
  &.enter-active,
  &.enter-done {
    transition: opacity 500ms 50ms;
    opacity: 0.4;
  }
  &.exit-active {
    transition: opacity 300ms;
    opacity: 0;
  }
`;
const Tracer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transform-origin: left;
  animation: ${LeadAnimation} 800ms ${({ theme }) => theme.ease.standard} infinite both 50ms;
`;
const Lead = styled(Tracer)`
  animation-name: ${LeadAnimation};
  background-color: ${({ theme }) => theme.gray[300]};
`;
const Cover = styled(Tracer)`
  animation-name: ${CoverAnimation};
  background-color: white;
`;

// Component
const Loader = ({ show = true }) => (
  <CSSTransition in={show} timeout={550} mountOnEnter unmountOnExit appear>
    <Wrapper>
      <Lead />
      <Cover />
    </Wrapper>
  </CSSTransition>
);
export default Loader;
