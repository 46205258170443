import { createGlobalStyle, keyframes } from 'styled-components/macro';

const fadeIn = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

const BaseStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }
  html, body, #root {
    height: 100%;
    width: 100%;
    width: 100vw;
    overflow: hidden;

    background-color: ${({ theme }) => theme.gray[50]};
    color: ${({ theme }) => theme.gray[900]};
  }

  .fade-in {
    opacity: 0;
    animation: ${fadeIn} 400ms ease 150ms forwards;
  }
`;

export default BaseStyles;
