import styled from 'styled-components/macro';

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 8px;

  outline: none;
  border: 1px solid ${({ theme }) => theme.gray[100]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.gray[50]};

  &:focus {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.gray[600]};
  }
  &:hover:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.gray[300]};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  transition: opacity 200ms ease;
`;
export default Input;
