import { auth } from '../lib/firebase';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { ReactComponent as RevelSVG } from '../svg/Revel.svg';
import PrimaryButton from './PrimaryButton';

const Wrapper = styled.nav`
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px;
`;
const LinkRow = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 12px;
    padding: 0 8px 2px;

    border-bottom: 2px solid ${({ theme }) => theme.gray[50]};

    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.gray[500]};
  }
  a:hover {
    color: ${({ theme }) => theme.gray[700]};
    border-bottom: 2px solid ${({ theme }) => theme.gray[200]};
  }
  a.active {
    color: ${({ theme }) => theme.gray[900]};
    border-bottom: 2px solid ${({ theme }) => theme.gray[700]};
  }
`;

interface Props {}
const Nav: React.FC<Props> = () => {
  return (
    <Wrapper>
      <RevelSVG width='128px' />
      <LinkRow>
        <NavLink to='/dashboard'>Dashboard</NavLink>
        <NavLink to='/feed'>Home feed</NavLink>
        <NavLink to='/events'>Events</NavLink>
        <NavLink to='/profiles'>Profiles</NavLink>
      </LinkRow>
      <PrimaryButton size='sm' onClick={() => auth.signOut()}>
        Logout
      </PrimaryButton>
    </Wrapper>
  );
};
export default Nav;
