import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/analytics';

const env = process.env;
const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_FIREBASE_APP_ID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
if (env.REACT_APP_RUNTIME_ENV === 'dev') {
  console.log('testing locally -- hitting local auth, functions and firestore emulators');
  // firebase.auth().useEmulator('http://localhost:9099/');
  firebase.functions().useEmulator('localhost', 5001);
  db.useEmulator('localhost', 8080);
}

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const fieldval = firebase.firestore.FieldValue;
export const storage = firebase.storage();
export const perf = firebase.performance();
export default firebase;
