import React, { useEffect } from 'react';
import { auth } from '../lib/firebase';
import { useSetRecoilState } from 'recoil';
import currentUserState from './currentUser';

const Session: React.FC = ({ children }) => {
  const setCurrentUser = useSetRecoilState(currentUserState);

  useEffect(
    () =>
      auth.onAuthStateChanged(async user => {
        setCurrentUser(user);
        if (user) {
          const { claims } = await user.getIdTokenResult();
          if (!claims.admin) {
            auth.signOut();
          }
        }
      }),
    [setCurrentUser]
  );

  return <>{children}</>;
};
export default Session;
