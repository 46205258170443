import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.button<{ block: boolean; size: 'sm' | 'md' | 'lg' }>`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  width: ${({ block }) => (block ? '100%' : 'auto')};
  padding: 8px 16px;
  padding: ${({ size }) => (size === 'sm' ? '4px 12px' : size === 'md' ? '8px 16px' : '12px 24px')};

  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 999px;
  background-color: white;

  font-size: ${({ size }) => (size === 'sm' ? 16 : size === 'md' ? 18 : 20)}px;
  color: ${({ theme }) => theme.gray[800]};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.gray[50]};
    color: ${({ theme }) => theme.gray[900]};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  transition: opacity 200ms ease;
`;

interface Props extends ComponentPropsWithoutRef<'button'> {
  block?: boolean;
  size?: 'sm' | 'md' | 'lg';
}
const SecondaryButton: React.FC<Props> = ({ children, block = false, size = 'md', ...rest }) => {
  return (
    <Wrapper block={block} size={size} {...rest}>
      {children}
    </Wrapper>
  );
};
export default SecondaryButton;
